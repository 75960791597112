.top{
    width: 100%;
    height: 44px;
    line-height: 44px;
    background-color: #333;
    text-align: center;
    color: #fff;
}
.top .menu{
    display: block;
    position: absolute;
    z-index: 3;
    top: 0;
    width: 44px;
    height: 44px;
    cursor: pointer;
}
.top .menu .menu_nr{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
}
.top .menu .menu_line {
    display: block;
    width: 17px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 12px;
    z-index: 1;
}
.top .menu .menu_line_top {
    top: 18px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.top .menu .menu_line_top.string1{
    top: 22px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    background-color: #FFF;
}

.top .menu .menu_line_bottom {
    bottom: 18px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.top .menu .menu_line_bottom.string2{
    bottom: 21px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    background-color: #FFF;
}
.top .index_logo{
    width: 150px;
    margin: 0 auto;
}
.top img{
    width: 45px;
}
.top .index_text{
    vertical-align: middle;
    font-size: 16px;
    color: #ff4a7d;
}
.slideUp{
   background: #333;
   position: absolute;
    width:100%;
    height:0;
    max-width: 640px;
    top:0;
    overflow: hidden;
    -webkit-transition: height .7s;
    -moz-transition: height .7s;
    -ms-transition: height .7s;
    -o-transition: height .7s;
    transition: height .7s;
    z-index: 2;
}
.slideUp.Ups{
    height:100%;
}

.slideUp ul{
    color:#fff;
    margin: 20px  30px 0 45px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.slideUp ul.ulss{
    margin: 50px 30px 0 45px;
}
.slideUp ul li{
    padding:5px 0 18px;
    text-align: left;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    opacity: 0.2;
    line-height: initial;
}
.slideUp ul li.lis{
    padding: 18px 0;
    opacity: 0.99;
}
.slideUp .one_border.hairlines .border_inner:after{
    background: #4A4A4A;
    bottom: 0;
}
.banner{
    margin-bottom: 8px;
}

.slogan{
    padding:0 10px;
}

.index_btn{
    text-align: center;
    margin:15px 0 34px 0;
}
.index_btn .btn{
    width: 96px;
    height: 32px;
    line-height: 32px;
    background-color: #FF4A7D;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    display: inline-block;
    margin-left: 48px;
}
.index_btn .btn:first-child{
    margin-left: 0;
}
.index_list li{
    margin-bottom: 10px;
    position: relative;
    min-height: 130px;
}
.index_list li .text{
    width: 100%;
    padding:0 15px;
    line-height: 40px;
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;
}
.index_list_arrow {
    display: inline-block;
    vertical-align: 0;
    width: 8px;
    height: 8px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    margin-left: 8px;
}
.index_bottom{
    padding:10px 15px 30px;
}
.index_bottom_menu{
    padding-bottom: 18px;
}
.index_bottom_menu a{
    padding-right: 20px;
}
.index_bottom_menu.one_border.hairlines .border_inner:after{
    background: #D8D8D8;
    left: 57px;
    width: 1px;
    height: 10px;
    top: 2px;
}
@media
only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:4/2),
only screen and (min-resolution:2dppx)
{
    .index_bottom_menu.one_border.hairlines .border_inner:after{
        -webkit-transform: scaleX(0.5);
        transform: scaleX(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
}
.index_copyright{
    font-size: 11px;
    color: #C1C1C1;
}
.index_copyright_con{
    padding-top: 18px;
}
.index_copyright.one_border.hairlines .border_inner:after{
    background: #D5D5D5;
    top:0;
}
.join_title{
    padding:0 18px 14px 18px;
}
.join_list{
    padding: 0 15px 25px;
}
.join_list li{
    width: 100%;
}
.join_list li .title{
    padding: 8px 0;
    position: relative;
    cursor: pointer;
}
.join_list li .list{
    width: 100%;
    font-size: 11px;
    color: #999;
    height:0;
    padding-bottom: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    overflow: hidden;
}
.join_list li .list.up_down{
    height:auto;
    padding-bottom: 8px;
}

.join_list li .list a{
    display: block;
    padding-bottom: 8px;
}
.join_list .icon{
    width:11px;
    height: 10px;
    background: url("http://pic.davdian.com/free/h5_add_icon.png") no-repeat;
    background-size: 11px;
    position: absolute;
    right:0;
    z-index: 1;
    display: inline-block;
    margin-top: 4px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.join_list .icon.addtran{
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.join_list .one_border.hairlines .border_inner:after{
    background: #D8D8D8;
    bottom:0;
}

.contact_con{
    padding:10px 42px;
    color: #666;
}
.about_list{
    padding:0 15px;
}
.about_list li{
    margin-bottom:20px;
}
.about_list_title{
    font-size: 16px;
    text-align: center;
    margin: 20px 0;
}
.about_con{
    font-size: 12px;
    color: #666;
    line-height: 18px;
}
.about_con_qualification{
    float: left;
    text-align: center;
}
.detail{
    padding: 25px 15px;
}

.about_detail_title{
    font-size: 16px;
    color: #333;
    line-height: 40px;
}

.about_detail_title img{
    width:40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 40px;
    border: 1px solid #dcdcdc;
}
.about_detail_title .vertical_align{
    vertical-align: text-top;
}
.about_detail_con{
    font-size: 12px;
    color: #666;
    line-height: 18px;
    margin-top: 6px;
    padding-bottom: 10px;
}

.join_detail_title{
    font-size: 16px;
    color: #333;
}
.join_detail_con{
    font-size: 12px;
    color: #666;
    line-height: 18px;
    margin-top: 15px;
}
.about_detail_con p,.join_detail_con p{
    margin-bottom: 5px;
}
.friend_list{
    padding:50px 10px 0;
}
.friend_list li{
    margin-bottom: 50px;
    position: relative;
}
.friend_list li img{
    min-height: 130px;
}
.friend_list li .text{
    width: 100%;
    margin:10px 0 0;
    box-sizing: border-box;
    font-size: 12px;
    color: #333;
    line-height: 18px;
    margin-bottom: 2px;
    overflow: hidden;
}
.list_more{
    text-align: right;
    color: #0076FF;
    font-size: 12px;
    margin-right:5px;
    margin-top: 5px;
}
.list_more_arrow {
    display: inline-block;
    vertical-align: 0;
    width: 8px;
    height: 8px;
    border-top: 1px solid #0076FF;
    border-right: 1px solid #0076FF;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    margin-left: 8px;
}
.course_filling{
    padding-bottom: 50px;
}
.course_filling img{
    width: 21px;
    height: 21px;
}
.service_list{
    padding: 25px 40px;
}
.service_list li{
    padding-bottom:20px;
    min-height: 50px;
}
.service_list li .icon{
    width:50px ;
    height: 50px;
    position: absolute;
}
.service_list li .text{
    float: left;
    font-size: 12px;
    color: #666;
    margin-left: 70px;
}
.service_QRCode{
    text-align: center;
    margin-bottom: 10px;
}
.service_QRCode img{
    width:90px;
    height: 90px;
    border: 1px solid #ddd;
}
.video_container{
    width: 100%;
    height: 56.25vw;
    max-height: 360px;
}
.video_container video{
    width: 100%;
}

.goods_container .qr_text{
    color: #fff;
    text-align: center;
}
.goods_container .detail:hover .hover_show{
    display: block;
}
.goods_container .goods_img_size{
    position: relative;
}
.goods_container .hover_show{
    position: absolute;
    background-color: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
}
.goods_container .detail{
    display: inline-block;
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
}
.goods_container .detail:nth-child(2n){
    margin-right: 0
}
.goods_container .name1{
    margin-bottom: 6px;
    line-height: 19px;
    font-size: 12px;
    color: #666666;
    white-space: inherit;
    height: 38px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-clamp: 2;
    padding-top: 5px;
}
.goods_container .price1{
    color: #FF4A7D;
}
